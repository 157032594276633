import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import RocketShip from './RocketShipSmaller';

// import TwitterFeed from './TwitterFeed'
// import logo from '../images/logo.png';
import { ArrowLeft } from '../../.cache/fast-refresh-overlay/helpers/keys';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #230d49;
  color: white;
  /* padding: 40px 30px 30px 40px; */
  max-height: 125px;

  @media all and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    /* height:auto; */
    min-height: 300px;
  }
`;

const LogoWrapper = styled.div`
  align-items: start;
  @media (max-width: 480px) {
    padding: 0px 60px 0px 60px;
  }
`;

const Header = () => (
  <HeaderWrapper>
    <LogoWrapper>
      <Link to="/" replace>
        <RocketShip />
      </Link>
    </LogoWrapper>
    <h1
      style={{
        paddingLeft: '60px',
        paddingBottom: '25px',
        fontFamily: 'Pacifico, cursive',
        fontSize: '2rem',
      }}
    >
      In Development!
    </h1>
  </HeaderWrapper>
);

export default Header;
