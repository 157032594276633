import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PendleHill from '../components/PendleHill';
import Influenced from '../components/Influenced';

const Development = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
      <title>Rocketship Productions - in development</title>
    </Helmet>
    <Header />
    <Influenced />
    <PendleHill />
    <Footer />
  </Layout>
);

export default Development;
