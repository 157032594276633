import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import TextHome from './styles/TextHome';
import pendleBible from '../files/Pendle-Hill-Bible-Rocketship-Productions.pdf';

const PendleHill = () => (
  <div>
    <StaticImage
      src="../images/pendle/pendle-hill-hero.jpg"
      alt="Pendle Hill"
    />
    <TextHome>
      <h3>Synopsis</h3>
      <>
        <p>
          When fracking company TerraNova start drilling on Pendle Hill they
          unleash a powerful preternatural energy that has lain dormant since
          the infamous Pendle Witches were put to trial and executed in 1612.
        </p>
        <p>
          Strange things start to happen across the town and especially to
          Annie, Shree and Gaia, all descendants of the original witches of
          Pendle and all of whom have now acquired enhanced intuitive powers.
        </p>
        <p>
          Across the season they use these powers to help solve murders and
          mysteries in the town. Our three unlikely witches are:
        </p>
        <p>
          <strong>Gaia:</strong> a former detective inspector just starting a
          sabbatical as a Yoga Teacher. Her ditzy, bright and breezy nature
          belies her sharp intellect and years of experience hunting down
          criminals.
        </p>
        <p>
          <strong>Annie:</strong> disillusioned with the cut-throat life and
          misogyny in the City, up-tight lawyer Annie, moves back to Pendle to
          run the local law practice. A fish-out-of-water away from London life
          she has to learn to use her intuition more and be more relaxed.
        </p>
        <p>
          <strong>Shree:</strong> a self-absorbed, materialistic “lady who
          lunches,” until her husband dies and she finds herself on the verge of
          bankruptcy. Her challenge is not to use her powers for her own ends
        </p>
      </>
      <>
        <h3>Project Overview</h3>
        <p>
          Our tone is very much in the light comedy drama sphere of classic
          British cozy mysteries such as Jonathan Creek. Our witches are not
          just focussed on solving murders, their cases are often more prosaic
          mysteries that affect the townfolk of Pendle and that need our
          witches’ special powers of perception and magick.{' '}
        </p>
        <p>
          Although our protagonists use “witchcraft” we are in a world very far
          removed from the usual teen fare of US supernatural witchy TV shows
          such as Charmed or even Sky’s The Discovery Of Witches. There are no
          evil vampires to fight and no demons to subdue only murders and
          mysteries to solve.
        </p>
        <p>
          There is a large degree of whimsy with a quirky tone across all areas
          of production, perhaps with elements of Pushing Daisies and Wes
          Anderson. We want to show a more gentle connected view of witchcraft
          that taps into the wider growing interest in neo-paganism and Wiccan
          beliefs.
        </p>
        <p>
          We are pleased to be working with practicing witch and author Carly
          Rose as a consultant to the series. Carly is one of the UK's leading
          new witches, with her podcast regularly topping the Apple charts as
          the most listened to podcast in spirituality genre in the UK and many
          other territories.
        </p>
      </>
      <a href={pendleBible} download style={{ color: 'green' }}>
        Download the Pendle Hill Project Bible
      </a>
    </TextHome>
  </div>
);

export default PendleHill;
