import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import TextHome from './styles/TextHome';
import influencedBible from '../files/Influenced-Bible-Rocketship-Productions.pdf';

const Influenced = () => (
  <div>
    <StaticImage src="../images/influenced/influenced.jpg" alt="Influenced!" />
    <TextHome>
      <h3>Synopsis</h3>
      <>
        <p>
          When gawky department store makeup sales assistant / wannabe beauty
          blogger Dora Pipkin gets stuck in a lift with cynical tech billionaire
          Darius Gatzenberg he enters into a bet with his friend that even she
          would become avaricious and self-serving given half the chance.
        </p>

        <p>
          To win his bet Darius uses his power and influence to "help" Dora
          become one of the biggest vloggers on the planet first in beauty and
          then through her "modern lifestyle brand" doop.com - we are talking
          Kardashian/Paltrow big here folks!
        </p>
        <p>
          And as she becomes more and more successful, with her own reality TV
          show and entourage, she gains more power and becomes ever more
          ruthless and self-obsessed. But as she wreaks her revenge and tramples
          over everyone in her path she is haunted by her instagram profile
          picture that appears to be getting more and more grotesque...
        </p>
        <p>
          But where will it end? And will this once meek and shy shop girl stop
          at nothing to show the world that she is leading the "perfect life" -
          even if that means killing her mystery blackmailer and others who get
          in her way?
        </p>
      </>
      <h3>Project Overview</h3>
      <>
        <p>
          Writer/director Nick Wild's faustian black comedy explores such
          contemporary themes as self-image/worth, fake news, new-age "remedies"
          and the need for social affirmation.
        </p>
        <p>
          As absurd as they may seem all events in Influenced are based on the
          antics and monstrous egos of real life influencers as they try and
          "sell the dream" to ever younger and more attention deficient viewers
          - we've just amped up the burlesque a little.
        </p>
        <p>
          Influenced shares much of its “tonal DNA” with
          <em>The Truman Show</em> and Gus Van Sant's <em>"To Die For"</em>, but
          also draws on thematic story elements from Darren Aronfsky's "Black
          Swan."
        </p>
      </>
      <a href={influencedBible} download style={{ color: 'green' }}>
        Download the Influenced Project Bible
      </a>
    </TextHome>
  </div>
);

export default Influenced;
